import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import IconApp from '../icons/icon-app.svg';
import IconGift from '../icons/icon-gift.svg';
import IconOffer from '../icons/icon-offer.svg';
import IconPercent from '../icons/icon-percent.svg';
import IconShipping from '../icons/icon-shipping.svg';
import BackgroundImage from '../images/uplift-5k/background.jpg';
import LogoImage from '../images/uplift-5k/logo.svg';

const benefitsList = [
  { icon: IconShipping, text: 'Shipping' },
  { icon: IconPercent, text: 'Percent' },
  { icon: IconApp, text: 'App' },
  { icon: IconGift, text: 'Gift' },
  { icon: IconOffer, text: 'Offer' },
];

const images = {
  ...defaultImages.heroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  benefitsList,
  images,
  theme: mergedTheme,
};
