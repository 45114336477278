import { lazy, Suspense, useEffect, useRef } from 'react';

import {
  AnalyticsApi,
  Button,
  defaultTheme,
  Footer,
  GatedRoute,
  GlobalStyles,
  LanguageDirectionProvider,
  mq,
  styled,
  ThemeProvider,
  useModal,
} from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { useAuthCheck } from '../../hooks/use-auth-check';
import { useBounce } from '../../hooks/use-bounce';
import { useDynamicStyle } from '../../hooks/use-dynamic-style';
import useQueryParams from '../../hooks/use-query-params';
import { useSaveQueryParams } from '../../hooks/use-save-query-params';
import { useSessionId } from '../../hooks/use-session-id';
import { clientsApi } from '../../services/api';
import { initExtole } from '../../services/extoleApi';
import themeMap from '../../themes/themeMap';
import { ENVIRONMENT_NAME } from '../../utilities/constants';
import { getTheme, isDynamicStyle } from '../../utilities/dynamicStyleUtils';
import { isRTL } from '../../utilities/localeUtils';
import { BrandFamily } from '../brand-family/BrandFamily';
import DynamicStyleBrandFamilyText from '../dynamic-style/DynamicStyleBrandFamilyText';
import FooterBody from '../footer-body/FooterBody';
import heroMap from '../hero/heroMap';
import DebuggerModal from '../modal/DebuggerModal';
import { Page } from '../page/Page';

const ChangeEmail = lazy(() => import('../../views/change-email/ChangeEmail'));
const ChangeEmailConfirm = lazy(() => import('../../views/change-email-confirm/ChangeEmailConfirm'));
const ChangePassword = lazy(() => import('../../views/change-password/ChangePassword'));
const CompleteProfile = lazy(() => import('../../views/complete-profile/CompleteProfile'));
const ConnectSocial = lazy(() => import('../../views/connect-social/ConnectSocial'));
const Continue = lazy(() => import('../../views/continue/Continue'));
const DeleteAccount = lazy(() => import('../../views/delete-account/DeleteAccount'));
const ForgotPassword = lazy(() => import('../../views/forgot-password/ForgotPassword'));
const Lander = lazy(() => import('../../views/lander/Lander'));
const Login = lazy(() => import('../../views/login/Login'));
const Logout = lazy(() => import('../../views/logout/Logout'));
const Profile = lazy(() => import('../../views/profile/Profile'));
const Register = lazy(() => import('../../views/register/Register'));
const ResetPassword = lazy(() => import('../../views/reset-password/ResetPassword'));
const SetPassword = lazy(() => import('../../views/set-password/SetPassword'));
const SSO = lazy(() => import('../../views/sso/SSO'));
const Welcome = lazy(() => import('../../views/welcome/Welcome'));

const StyledColumn = styled.div({
  alignItems: 'center',
  display: 'none',
  flex: '1',
  flexBasis: '48%',
  flexDirection: 'column',
  minHeight: '100%',
});

const RightColumn = styled(StyledColumn)(({ theme }) => ({
  color: theme.colors.textPrimary,
  display: 'flex',
  '#debug': {
    marginTop: '20px',
  },
}));

const LeftColumn = styled(StyledColumn)(() =>
  mq({
    flexDirection: 'column',
    display: [null, null, null, 'flex'],
  }),
);

const LeftColumnInside = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: theme.background.image,
  backgroundPosition: theme.background.position,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'fixed',
  width: '50%',
}));

const RightColumnInside = styled.div({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  padding: '20px 5px 15px',
  width: '85%',
});

const MobileHero = styled.div(
  mq({
    display: [null, null, null, 'none'],
    width: '100%',
  }),
);

const AppBody = () => {
  useSaveQueryParams();
  const {
    client: { clientId },
    queryParams: { platform, region: queryRegion, sdkVersion, style: styleFromState, utmCampaign, webview },
    user: { isLoggedIn },
  } = useSelector((state) => state);
  const { i18n, t } = useTranslation(['footer']);
  const { openModal, RenderModal } = useModal();
  const sessionId = useSessionId();
  const { data: client } = clientsApi.useLoggedInQuery(clientId, { refetchOnMountOrArgChange: true });
  const user = client?.user;
  const { Track } = useTracking({
    app_id: 'ASICSID',
    client_id: clientId,
    environment: ENVIRONMENT_NAME,
    platform,
    region: queryRegion || client?.clientConfig?.regionId,
    sdk_version: sdkVersion,
    session_id: sessionId,
    source_id: 'ASICS-ID',
    styleFromState,
    utm_campaign: utmCampaign,
    webview,
  }, {
    dispatch: (data) => AnalyticsApi.logAnalytics({
      asics_id: user?.asicsId,
      ...data,
      locale: i18n.language,
    }, true),
  });

  useAuthCheck();
  useBounce();

  const queryParams = useQueryParams();
  useEffect(() => {
    initExtole(clientId, queryParams);
  }, [clientId, queryParams]);

  // Get the style directly from query params to prevent getting a cached value, and therefore an old dynamic style.
  const style = queryParams.get('style') || styleFromState;
  useDynamicStyle({ style, themeMap });   // If this is a dynamic style, update the themeMap.dynamic_theme theme with styles from the API.
  const location = useLocation();

  /**
   * previouslyLoggedInRef holds a boolean indicating if a user came to ASICS ID and was
   * already logged in from a previous visit, once a user logs out, this will change to false
   */
  const previouslyLoggedInRef = useRef(isLoggedIn);
  useEffect(() => {
    if (previouslyLoggedInRef && !isLoggedIn) {
      previouslyLoggedInRef.current = isLoggedIn;
    }
  }, [isLoggedIn]);

  /**
   * direction holds a value indicating if the current locale selected reads left-to-right (ltr)
   * or right-to-left (rtl)
   */
  const direction = isRTL(i18n.language) ? 'rtl' : 'ltr';

  const { desktop: HeroComponent, mobile: MobileHeroComponent } = heroMap[getTheme(style)] ?? heroMap.default;

  return (
    <Track>
      <GlobalStyles />
      <ThemeProvider theme={themeMap[getTheme(style)]?.theme || defaultTheme}>
        <LanguageDirectionProvider direction={direction}>
          <Page theme={getTheme(style)}>
            <Suspense fallback={<div />}>
              <LeftColumn>
                <LeftColumnInside>{HeroComponent && <HeroComponent style={getTheme(style)} />}</LeftColumnInside>
              </LeftColumn>
              <MobileHero>
                {MobileHeroComponent && (
                  <MobileHeroComponent
                    isMobile
                    style={getTheme(style)}
                  />
                )}
              </MobileHero>
            </Suspense>
            <RightColumn>
              <RightColumnInside dir={direction}>
                <Suspense fallback={<div />}>
                  <Switch>
                    {/* Auth routes */}
                    <GatedRoute component={ChangeEmail} conditional={isLoggedIn} path="/change-email" redirect="/" />
                    <GatedRoute component={ChangePassword} conditional={isLoggedIn} path="/change-password" redirect="/" />
                    <GatedRoute component={Continue} conditional={isLoggedIn} path="/continue" redirect="/" />
                    <GatedRoute component={DeleteAccount} conditional={isLoggedIn} path="/delete" redirect="/" />
                    <GatedRoute component={Profile} conditional={isLoggedIn} path="/profile" redirect="/" />
                    <GatedRoute component={CompleteProfile} conditional={isLoggedIn} path="/complete-profile" redirect="/" />
                    <GatedRoute component={Logout} conditional={isLoggedIn} path="/logout" redirect="/" />
                    <GatedRoute component={Welcome} conditional={isLoggedIn} path="/welcome" redirect="/" />
                    <GatedRoute component={SetPassword} conditional={isLoggedIn} path="/set-password" redirect="/" />

                    {/* No auth routes */}
                    <Route
                      component={ChangeEmailConfirm}
                      path="/change-email-confirm"
                    />
                    <Route
                      component={ForgotPassword}
                      path="/forgot-password"
                    />
                    <Route
                      component={ResetPassword}
                      path="/reset-password"
                    />
                    <Route component={SSO}
                          path="/single-sign-on"
                    />
                    <GatedRoute
                      component={ConnectSocial}
                      conditional={!isLoggedIn}
                      path="/connect-social"
                      redirect="/welcome"
                    />
                    <GatedRoute
                      exact
                      component={Lander}
                      conditional={!isLoggedIn}
                      path="/"
                      redirect={previouslyLoggedInRef.current ? '/continue' : '/welcome'}
                    />
                    <GatedRoute
                      component={Login}
                      conditional={!isLoggedIn}
                      path="/login"
                      redirect={previouslyLoggedInRef.current ? '/continue' : '/welcome'}
                    />
                    <GatedRoute
                      component={Register}
                      conditional={!isLoggedIn}
                      path="/register"
                      redirect={previouslyLoggedInRef.current ? '/continue' : '/welcome'}
                    />
                    {/* Redirects */}
                    {/* Redirects should try to persist the query params in the url so we don't lose/overwrite query params before they're processed */}
                    <Redirect
                      from="/newsletter-opt-in"
                      to={{ pathname: '/profile', search: location.search }}
                    />
                    <Route path="/*">
                      <Redirect to={{ pathname: isLoggedIn ? 'continue' : '/', search: location.search }} />
                    </Route>
                  </Switch>
                </Suspense>
                {ENVIRONMENT_NAME !== 'prod' && (
                  <Button
                    color=""
                    id="debug"
                    onClick={openModal}
                  >
                    Debug
                  </Button>
                )}
              </RightColumnInside>
              <BrandFamily style={getTheme(style)} />
              {
                isDynamicStyle(style) &&
                <DynamicStyleBrandFamilyText>One Account</DynamicStyleBrandFamilyText>
              }
              <Footer copyrightText={t('copyrightText', { year: new Date().getFullYear() }) } i18n={i18n}>
                <FooterBody />
              </Footer>
            </RightColumn>
            <RenderModal>
              <DebuggerModal />
            </RenderModal>
          </Page>
        </LanguageDirectionProvider>
      </ThemeProvider>
    </Track>
  );
};

AppBody.propTypes = {};

AppBody.defaultProps = {};

export default AppBody;
