import { defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import LogoImage from '../images/raceroster-meta-tt/logo.png';

const images = {
  heroLogo: LogoImage,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: 'linear-gradient(#FF1659, #FF1659)',
    position: 'center',
  },
  sizes: {
    logo: '45%',
    mobileLogo: '27%',
  },
  space: {
    logo: 'auto',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
