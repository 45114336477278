import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/haglofs/background.jpg';
import LogoImage from '../images/haglofs/logo.svg';

const images = {
  ...defaultImages.heroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
    position: 'top center',
    mobile: `url(${BackgroundImage})`,
  },
  buttonShape: {
    default: defaultTheme.buttonShape.square,
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.grey[700],

    primary: '#ff4338',
    primaryHover: defaultTheme.colors.primary,
    textPrimary: '#041e41',
  },
  fonts: {
    globalFont: 'GT-Eesti, Graphik, arial, sans-serif',
  },
  fontWeights: {
    regular: 300,
    medium: 400,
    semiBold: 500,
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
