import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/stepnote/background.jpg';

const images = { ...defaultImages.heroLogo };

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  space: {
    logo: 'auto',
  },
  sizes: {
    logo: '70%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
