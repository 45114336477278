import { defaultImages, defaultTheme, BlackOneAsicsLogo as HeroLogo } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/asics-walking/background.jpg';
import BlackBrandFamilyImage from '../images/asics-walking/brand-family-black.svg';
import IconApp from '../images/asics-walking/icon-app.svg';
import IconGift from '../images/asics-walking/icon-gift.svg';
import IconPoints from '../images/asics-walking/icon-points.svg';
import IconShipping from '../images/asics-walking/icon-shipping.svg';
import IconStar from '../images/asics-walking/icon-star.svg';

const benefitsList = [
  { icon: IconShipping, text: 'Shipping' },
  { icon: IconApp, text: 'App' },
  { icon: IconGift, text: 'Gift' },
  { icon: IconStar, text: 'Star' },
  { icon: IconPoints, text: 'Points' },
];

const images = {
  ...defaultImages.heroLogo,
  heroLogo: HeroLogo,
  brandFamily: BlackBrandFamilyImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
    mobile: 'black',
  },
  buttonShape: {
    default: defaultTheme.buttonShape.square,
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.grey[700],

    primary: defaultTheme.colors.blackDarkest,
    primaryHover: defaultTheme.buttonShape.primary,

    textPrimary: defaultTheme.buttonShape.textBlack,
  },
  sizes: {
    logo: '60%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  benefitsList,
  images,
  theme: mergedTheme,
};
