import { useEffect } from 'react';

import { raceRosterDynamicStyleApi } from '../services/api';
import { createDynamicTheme, getRaceEventId, isDynamicStyle } from '../utilities/dynamicStyleUtils';

export const useDynamicStyle = ({ style, themeMap }) => {
  // We're not using the store, so we use useLazyQuery which allows us to set the theme after the API response is returned.
  const [getStyles] = raceRosterDynamicStyleApi.useLazyGetRaceRosterStylesQuery();

  useEffect(() => {
    if (isDynamicStyle(style)) {
      getStyles(getRaceEventId(style))
        .unwrap()
        .then(
          (data) => createDynamicTheme(data, themeMap),
          () => {},
        );
    }
  }, [style, themeMap]); // eslint-disable-line react-hooks/exhaustive-deps
};
