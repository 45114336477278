import { defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/raceroster-aop-demo/background.png';
import LogoImage from '../images/raceroster-aop-demo/logo.png';

const images = {
  heroLogo: LogoImage,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  sizes: {
    logo: '40%',
    mobileLogo: '27%',
  },
  space: {
    logo: 'auto',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
