import { colors, defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/onitsuka-tiger-uk/background.jpg';
import LogoImage from '../images/onitsuka-tiger-uk/logo-black.svg';

const images = {
  ...defaultImages.heroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  buttonShape: {
    buttonShape: defaultTheme.buttonShape.square,
  },
  colors: {
    inputBorder: colors.grey[600],
    inputBorderFocused: colors.black,
    inputText: colors.textBlack,

    primary: '#7f0019',
    primaryHover: '#af0022',

    textPrimary: colors.textBlack,
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
