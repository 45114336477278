import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useQueryParams from './use-query-params';
import { clientsApi } from '../services/api';
import { setClientInfo } from '../store/clientSlice';
import { setOneTimeUseQueryParams, setPersistableQueryParams } from '../store/queryParamsSlice';
import { setEmail } from '../store/userSlice';

/**
 * React hook for handling query params.
 * Query params we currently support:
 *   client_id (Done)
 *   style (Done)
 *   locale (Done)
 *   bounce (Done)
 *   grant_type (Done)
 *   code_challenge_method (Done)
 *   code_challenge (Done)
 *   scope (Done)
 *   state (Done)
 *   utm_campaign (Done)
 *   utm_source (Done)
 *   utm_medium (Done)
 *   utm_term (Done)
 *   utm_content (Done)
 *   no_confirm_email (done)
 *   callback_url
 *   terms_privacy_country (done)
 *   terms_privacy_version (done)
 *   webview (Done)
 *   thirdp_auth (Done)
 *   redirect_uri (Done)
 *   no_analytics
 *   platform (Done)
 *   sdk_version (Done)
 *   region (Done)
 *   email (Done) This param is used in the reset-password redirect from email. This is used in the reset
 *                password request.
 *   token (Done) This param is used in the reset-password redirect from email to let the user reset their
 *                password without needing to log in.
 *   asics_id (Done) This param is used in the reset-password redirect from email.
 */
export const useSaveQueryParams = () => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state) => state.client);
  const { i18n } = useTranslation();
  const queryParams = useQueryParams();

  useEffect(() => {
    const newLocale = queryParams.get('locale');
    if (newLocale) {
      i18n.changeLocale(newLocale);
    }

    dispatch(
      setOneTimeUseQueryParams({
        asicsId: queryParams.get('asics_id'),
        codeChallenge: queryParams.get('code_challenge'),
        codeChallengeMethod: queryParams.get('code_challenge_method'),
        redirectUri: queryParams.get('redirect_uri'),
        scope: queryParams.get('scope'),
        state: queryParams.get('state'),
        token: queryParams.get('token'),
        utmCampaign: queryParams.get('utm_campaign'),
        utmContent: queryParams.get('utm_content'),
        utmMedium: queryParams.get('utm_medium'),
        utmSource: queryParams.get('utm_source'),
        utmTerm: queryParams.get('utm_term'),
      }),
    );

    dispatch(
      setPersistableQueryParams({
        agreeTermsCountry: queryParams.get('terms_privacy_country'),
        agreeTermsVersion: queryParams.get('terms_privacy_version'),
        bounce: queryParams.get('bounce'),
        email: queryParams.get('email'),
        grantType: queryParams.get('grant_type'),
        locale: queryParams.get('locale'),
        noConfirmEmail: queryParams.get('no_confirm_email'),
        platform: queryParams.get('platform'),
        region: queryParams.get('region'),
        sdkVersion: queryParams.get('sdk_version'),
        style: queryParams.get('style'),
        thirdPartyAuth: queryParams.get('thirdp_auth'),
        webview: queryParams.get('webview'),
      }),
    );

    dispatch(
      setEmail({
        email: queryParams.get('email'),
      }),
    );

    dispatch(clientsApi.endpoints.loggedIn.initiate(queryParams.get('client_id') || clientId || 'idm'))
      .unwrap()
      .then(({ clientConfig }) => {
        dispatch(setClientInfo({
          clientId: clientConfig?.clientId,
          formToggles: clientConfig,
        }));
      })
      .catch((e) => console.log(e)); // eslint-disable-line no-console
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [];
};
