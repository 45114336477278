import { mq, styled, useLanguageDirection } from '@asicsdigital/oneasics-blocks';

import themeMap from '../../themes/themeMap';

const StyledPage = styled.div(
  mq({
    display: 'flex',
    flexDirection: ['column', null, null, 'row'],
    minHeight: '100%',
  }),
);

/**
 * The container for all elements on a page.
 */
export const Page = ({ children, theme = '' }) => {
  const { direction } = useLanguageDirection();

  return (
    <StyledPage className={themeMap[theme]?.theme} dir={direction}>{children}</StyledPage>
  );
};
