import { defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import LogoImage from '../images/perth-city-to-surf/logo.png';

const images = {
  heroLogo: LogoImage,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: 'linear-gradient(#0A2E70, #0A2E70)',
    position: 'center',
  },
  sizes: {
    logo: '27%',
    mobileLogo: '27%',
  },
  space: {
    logo: 'auto',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
