import { mq, styled } from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';

const BrandFamilyTitleText = styled.h3(({ theme }) => (
  mq({
    textAlign: 'center',
    display: ['none', 'none', 'none', 'block'],
    width: '65%',
    fontWeight: theme.fontWeights.light,
    fontSize: theme.fontSizes.normal,
    marginBottom: '40px',
  })
));

const DynamicStyleBrandFamilyText = () => {
  const { t } = useTranslation();
  return (<BrandFamilyTitleText>{t('lander.oneAccount')}</BrandFamilyTitleText>);
};

export default DynamicStyleBrandFamilyText;
