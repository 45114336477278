import { mq, styled } from '@asicsdigital/oneasics-blocks';

import themeMap from '../../themes/themeMap';

const BrandFamilyImage = styled.img(({ imageStyle }) => {
  const defaultStyle = {
    padding: '25px 20px 25px 20px',
  };

  const styles = {
    asics_perf_jp:
      mq({
        maxWidth: '100%',
        padding: ['25px 0px', null, null, null],
      }),
    dynamic_theme:
      mq({
        display: ['none', 'none', 'none', 'block'],
        width: ['25%', '20%', '15%', '125px'],
        marginBottom: '5px',
      }),
  };

  return styles[imageStyle] || defaultStyle;
});

/**
 * The image collection of brands represented by style.
 */
export const BrandFamily = ({ style = '' }) => {
  const image = themeMap[style]?.images?.brandFamily;

  return (
    Boolean(image) && (
      <BrandFamilyImage
        alt="ASICS Brand Family"
        imageStyle={style}
        src={image}
      />
    )
  );
};
