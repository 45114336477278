import { colors, defaultImages, defaultTheme, WhiteOneAsicsLogo as LogoImage } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BlackBrandFamilyImage from '../images/team-red/brand-family-black.svg';
import HeroImage from '../images/team-red/hero.png';
import HeaderImage from '../images/team-red/membership.svg';
import DescriptionImage from '../images/team-red/we-are.svg';

const images = {
  ...defaultImages.heroLogo,
  brandFamily: BlackBrandFamilyImage,
  description: DescriptionImage,
  header: HeaderImage,
  hero: HeroImage,
  brandLogo: LogoImage, // This is used in TeamRedHero in the membership project
};

const theme = {
  background: {
    image: 'linear-gradient(#dd2c00, #dd2c00)',
  },
  buttonShape: {
    default: '0',
  },
  colors: {
    inputBorder: colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: colors.grey[700],

    primary: '#dd2c00',
    primaryHover: '#f63000',
    primaryContrast: defaultTheme.colors.white,

    textPrimary: defaultTheme.colors.textBlack,
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
