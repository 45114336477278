import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/raceroster-run-melborne/background.jpeg';

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
    position: 'center',
  },
  sizes: {
    logo: '70%',
  },
  space: {
    logo: defaultTheme.space.logoBottom,
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images: defaultImages,
  theme: mergedTheme,
};
