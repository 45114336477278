import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import localeFallbacks from './locales/fallbacks.json';
import locales from './locales/locales.json';
import { getTheme } from './utilities/dynamicStyleUtils';

const namespaces = ['base', 'errors', 'form', 'footer', 'overrides', 'views'];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },

    cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US

    fallbackLng: localeFallbacks,

    debug: false,

    defaultNS: 'views',
    ns: namespaces,
    fallbackNS: namespaces,

    load: 'currentOnly',

    parseMissingKeyHandler: () => null,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'locale', // detect the language from the locale query param
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',

      // cache user language
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
  });

i18n.locales = locales;

i18n.localeFallbacks = localeFallbacks;

/**
 * Use this method over `changeLanguage` to allow for fallback locales.
 * @param {String} locale
 */
i18n.changeLocale = (locale) => {
  const newLocale =
    locales.find((loc) => loc.value.toLowerCase() === locale.toLowerCase())?.value ??
    i18n.localeFallbacks[locale]?.[0] ??
    i18n.localeFallbacks.default[0];
  if (newLocale && newLocale !== i18n.language) {
    i18n.changeLanguage(newLocale);
  }
};

i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

i18n.updateT = style => i18n.getFixedT(null, ['overrides', `${getTheme(style)}-views`, 'views']);

export default i18n;
