import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import IconApp from '../icons/icon-app.svg';
import IconGift from '../icons/icon-gift.svg';
import IconPoints from '../icons/icon-points.svg';
import IconShipping from '../icons/icon-shipping.svg';
import IconStar from '../icons/icon-star.svg';
import BackgroundImage from '../images/asics-perf-jp/background.jpg';
import BlueBrandFamilyImage from '../images/asics-perf-jp/brand-family-blue.svg';

const benefitsList = [
  { icon: IconShipping, text: 'Shipping' },
  { icon: IconApp, text: 'App' },
  { icon: IconGift, text: 'Gift' },
  { icon: IconStar, text: 'Star' },
  { icon: IconPoints, text: 'Points' },
];

const images = {
  ...defaultImages.heroLogo,
  brandFamily: BlueBrandFamilyImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  sizes: {
    logo: '60%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  benefitsList,
  images,
  theme: mergedTheme,
};
