import AafMagento from './aaf-magento';
import AsicsPerfANA from './asics-perf-ana';
import AsicsPerfJPTheme from './asics-perf-jp';
import AsicsStudioTheme from './asics-studio';
import AsicsWalkingTheme from './asics-walking';
import BrightonMarathon from './brighton-marathon';
import Burnie10Theme from './burnie-10';
import DynamicTheme from './dynamic-theme';
import GCMTheme from './gcm';
import HaglofsTheme from './haglofs';
import MindUplifterTheme from './minduplifter';
import OnitsukaTigerANATheme from './onitsuka-tiger-ana';
import OnitsukaTigerUKTheme from './onitsuka-tiger-uk';
import PerthCityToSurf from './perth-city-to-surf';
import Raceroster25Theme from './raceroster-25';
import RacerosterAopDemo from './raceroster-aop-demo';
import RacerosterHerveyBay100Theme from './raceroster-hervey-bay-100';
import RacerosterMetaTT from './raceroster-meta-tt';
import RacerosterRunMelborne from './raceroster-run-melborne';
import RacerosterSydneyMarathon from './raceroster-sydney-marathon';
import RebrandLite from './rebrand-lite';
import RMTheme from './rm';
import RunkeeperTheme from './runkeeper';
import StepnoteTheme from './stepnote';
import TeamRedTheme from './team-red';
import Uplift5kTheme from './uplift-5k';

export default {
  aaf_magento: AafMagento,
  aeg_outlet_instore: RebrandLite, // delete?
  aeg_outlet_instore_uk_only: RebrandLite, // delete?
  aesop: RebrandLite,
  asics_mobile_jp: AsicsPerfJPTheme,
  asics_outlet_eu: RebrandLite,
  asics_perf_ana: AsicsPerfANA,
  asics_perf_au: RebrandLite,
  asics_perf_jp: AsicsPerfJPTheme,
  asics_perf_kr: RebrandLite,
  asics_perf_marathon: RebrandLite, // delete?
  asics_perf_uk: RebrandLite,
  asics_studio: AsicsStudioTheme,
  asics_walking: AsicsWalkingTheme,
  brighton_marathon: BrightonMarathon,
  burnie_10: Burnie10Theme,
  dynamic_theme: DynamicTheme,
  gcm: GCMTheme,
  haglofs: HaglofsTheme,
  minduplifter: MindUplifterTheme,
  oasis: RebrandLite,
  onitsuka_tiger_ana: OnitsukaTigerANATheme,
  onitsuka_tiger_au: OnitsukaTigerANATheme,
  onitsuka_tiger_uk: OnitsukaTigerUKTheme,
  onitsuka_tiger_kr: OnitsukaTigerANATheme,
  perth_city_to_surf: PerthCityToSurf,
  uplift_5k: Uplift5kTheme, // delete?
  raceroster_25: Raceroster25Theme, // delete?
  raceroster_hervey_bay_100: RacerosterHerveyBay100Theme,
  raceroster_aop_demo: RacerosterAopDemo,
  raceroster_run_melborne: RacerosterRunMelborne,
  raceroster_sydney_marathon: RacerosterSydneyMarathon,
  raceroster_meta_tt: RacerosterMetaTT,
  raceroster_rkgo: Raceroster25Theme, // delete?
  rm: RMTheme,
  runkeeper: RunkeeperTheme,
  stepnote: StepnoteTheme,
  team_red: TeamRedTheme,
};
