import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BlackBrandFamilyImage from '../images/brand-family-black.svg';
import BackgroundImage from '../images/raceroster-25/background.jpg';

const images = {
  ...defaultImages.heroLogo,
  brandFamily: BlackBrandFamilyImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  buttonShape: {
    default: defaultTheme.buttonShape.square,
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.grey[700],

    primary: defaultTheme.colors.smurfBlue,
    primaryHover: defaultTheme.colors.smurfBlueLight,
    primaryContrast: defaultTheme.colors.smurfBlueContrast,

    textPrimary: defaultTheme.colors.textBlack,
  },
  space: {
    logo: '10%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
