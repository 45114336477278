import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/onitsuka-tiger-ana/background.jpg';
import LogoImage from '../images/onitsuka-tiger-ana/logo-black.svg';
import HeroLogo from '../images/onitsuka-tiger-ana/logo-white.svg';

const images = {
  ...defaultImages.heroLogo,
  heroLogo: HeroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  buttonShape: {
    default: defaultTheme.buttonShape.square,
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.textBlack,

    primary: '#7f0019',
    primaryHover: '#af0022',

    textPrimary: defaultTheme.colors.textBlack,
  },
  sizes: {
    logo: '28%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
