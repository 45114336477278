import { defaultImages, defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/asics-studio/background.jpg';
import LogoImage from '../images/asics-studio/logo.svg';

const images = {
  ...defaultImages.heroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
    position: 'top center',
  },
  buttonShape: {
    default: '0',
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.grey[700],

    primary: '#f7485d',
    primaryHover: defaultTheme.colors.primary,

    textPrimary: '#083a81',
  },
  fontWeights: {
    regular: 300,
    medium: 500,
    semiBold: 400,
  },
  space: {
    logo: '35%',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
