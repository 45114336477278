import { lazy } from 'react';

const AsicsStudioHero = lazy(() => import('./AsicsStudioHero'));
const DynamicHero = lazy(() => import('./mobile/DynamicHero'));
const LogoOnlyHero = lazy(() => import('./LogoOnlyHero'));
const OneAsicsHero = lazy(() => import('./OneAsicsHero'));
const OnitsukaTigerHero = lazy(() => import('./OnitsukaTigerHero'));
const RacerosterHero = lazy(() => import('./RacerosterHero'));
const TeamRedHero = lazy(() => import('./TeamRedHero'));
const BlueLogoHero = lazy(() => import('./mobile/BlueLogoHero'));
const WhiteLogoHero = lazy(() => import('./mobile/WhiteLogoHero'));

export default {
  aaf_magento: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  asics_mobile_jp: {
    mobile: OneAsicsHero,
    desktop: OneAsicsHero,
  },
  asics_perf_jp: {
    mobile: OneAsicsHero,
    desktop: OneAsicsHero,
  },
  asics_studio: {
    desktop: AsicsStudioHero,
  },
  asics_walking: {
    mobile: OneAsicsHero,
    desktop: OneAsicsHero,
  },
  brighton_marathon: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  burnie_10: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  default: {
    mobile: BlueLogoHero,
    desktop: LogoOnlyHero,
  },
  dynamic_theme: {
    mobile: DynamicHero,
    desktop: LogoOnlyHero,
  },
  gcm: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  haglofs: {
    mobile: WhiteLogoHero,
    desktop: LogoOnlyHero,
  },
  minduplifter: {},
  oasis: {
    mobile: BlueLogoHero,
    desktop: LogoOnlyHero,
  },
  onitsuka_tiger_ana: {
    mobile: OnitsukaTigerHero,
    desktop: OnitsukaTigerHero,
  },
  onitsuka_tiger_au: {
    mobile: OnitsukaTigerHero,
    desktop: OnitsukaTigerHero,
  },
  onitsuka_tiger_kr: {
    mobile: OnitsukaTigerHero,
    desktop: OnitsukaTigerHero,
  },
  onitsuka_tiger_uk: {},
  uplift_5k: {
    mobile: OneAsicsHero,
    desktop: OneAsicsHero,
  },
  perth_city_to_surf: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  raceroster_25: {
    mobile: RacerosterHero,
    desktop: RacerosterHero,
  },
  raceroster_hervey_bay_100: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  raceroster_rkgo: {
    mobile: RacerosterHero,
    desktop: RacerosterHero,
  },
  raceroster_meta_tt: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  raceroster_aop_demo: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  raceroster_run_melborne: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  raceroster_sydney_marathon: {
    mobile: LogoOnlyHero,
    desktop: LogoOnlyHero,
  },
  runkeeper: {
    desktop: LogoOnlyHero,
  },
  team_red: {
    mobile: TeamRedHero,
    desktop: TeamRedHero,
  },
};
