import merge from 'lodash/merge';

export const createDynamicTheme = (styles, themeMap) => {
  if (!styles) {
    return;
  }

  let backgroundImage = 'none';

  if (styles?.backgroundImage) {
    backgroundImage = `url(${styles?.backgroundImage})`;
  } else if (styles?.backgroundColor) {
    backgroundImage = `linear-gradient(#${styles?.backgroundColor}, #${styles?.backgroundColor})`;
  }

  themeMap.dynamic_theme = merge(
    {},
    themeMap.dynamic_theme,
    {
      theme: {
        background: { image: backgroundImage },
      },
    },
    {
      images: { heroLogo: styles?.logo },
    },
  );
};

const uuidRegex = '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}';

// Matches *:[uuid]. For example: "race-roster-event:d56420f9-3e65-4658-8d66-2e08334e30c9"
export const isDynamicStyle = (style) => new RegExp(`^.*:${uuidRegex}`).test(style);

export const getRaceEventId = (style) => style.match(new RegExp(uuidRegex))?.[0];

export const getTheme = (style) =>  isDynamicStyle(style) ? 'dynamic_theme' : style;

