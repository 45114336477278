import { defaultTheme } from '@asicsdigital/oneasics-blocks';
import merge from 'lodash/merge';

import BackgroundImage from '../images/runkeeper/background.jpg';
import HeroLogo from '../images/runkeeper/logo-white.svg';
import LogoImage from '../images/runkeeper/logo.svg';

const images = {
  heroLogo: HeroLogo,
  brandLogo: LogoImage,
};

const theme = {
  background: {
    image: `url(${BackgroundImage})`,
  },
  colors: {
    inputBorder: defaultTheme.colors.grey[600],
    inputBorderFocused: defaultTheme.colors.black,
    inputText: defaultTheme.colors.grey[700],

    primaryHover: defaultTheme.colors.primary,

    textPrimary: defaultTheme.colors.textBlack,
  },
  sizes: {
    logo: '70%',
  },
  space: {
    logo: 'auto',
  },
};

const mergedTheme = merge({}, defaultTheme, theme);

export default {
  images,
  theme: mergedTheme,
};
